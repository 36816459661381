<template>
  <div class="app-container">
    <div id="app-contain">
    <div class="filter-container" id="filter-container">
     
      <el-input v-model="listQuery.name" placeholder="名称" style="width: 200px;" class="filter-item" clearable />

      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="loadList">
        查询
      </el-button>

      <!-- <el-button style="float: right" class="filter-item" type="primary" icon="el-icon-circle-plus" @click="addReportItem">
          报表打印
        </el-button> -->
        
    </div>
 
    <el-table
      v-loading="listLoading"
      :data="list"
      :height="tabheight"
      border
      fit
      highlight-current-row
      style="width: 100%;overflow:auto"
    >
      <el-table-column label="序号" type="index" align="center" width="50x" />
      <el-table-column label="名称" header-align="center" align="left" prop="name" min-width="150" />
      <el-table-column label="年度" header-align="center" align="left" prop="stYear" width="150" />
      <el-table-column label="开始时间" header-align="center" align="right" prop="beginDate" min-width="200" />
      <el-table-column label="截止时间" header-align="center" align="right" prop="endDate" min-width="200" />
       <el-table-column label="填报状态" header-align="center" align="left" width="150">
        <template slot-scope="scope">
          <span>{{ getDicName(scope.row.state,'YW_TASK_SCHOOL_STATE') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" header-align="center" align="right" prop="createTimeTi" width="200" show-overflow-tooltip />
      <el-table-column label="提交人" header-align="center" align="left" prop="tjrName" width="100" />
      <el-table-column
        fixed="right"
        label="操作"
        width="200"
        header-align="center"
        align="left"
      >
        <template slot-scope="scope">
          <span>
            
            <el-button v-if="scope.row.state!=2&&scope.row.state!=3&&scope.row.timeState" type="primary" size="small" @click="addReport(scope.row)">填报</el-button>
            <el-button  v-if="scope.row.state==2||scope.row.state==3" type="primary" size="small" @click="handleSubmitTest(scope.row)">查看</el-button>
              <el-button  type="primary" v-if="scope.row.state==4" size="small" @click="handleEdit(scope.row)">审批日志</el-button>

          </span>
        </template>
      </el-table-column>

    </el-table>
    <pagination id="pagination" v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="loadList" />
     <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisibleDF" width="95%"   append-to-body :title="'数据填报'">
        <add-index v-if="dialogVisibleDF" ref="dataFillingIndex" :proid="code" :visible.sync="dialogVisibleDF" />
      </el-dialog>
       <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisibleTask" width="90%" append-to-body :title="'填报详情'">
        <task-index v-if="dialogVisibleTask" ref="taskindex" :proid="code" :visible.sync="dialogVisibleTask" />
      </el-dialog>
         <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisibleExaLog" width="70%" append-to-body :title="'审批日志'">
        <examine-log v-if="dialogVisibleExaLog" ref="exaLog" :proid="code" :visible.sync="dialogVisibleExaLog" />
      </el-dialog>
      <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisibleARI" width="70%" append-to-body :title="'生成报表'">
        <add-report-item v-if="dialogVisibleARI" ref="examineindex" :proid="code" :visible.sync="dialogVisibleARI" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import { page, submitTest, release, revoke ,getDeployTreeList} from '@/api/api-deploy'
import { getDictListByCode } from '@/api/dict'
import Pagination from '@/components/Pagination'
import { tableHeight2 } from '@/utils/tableHeight'
import { Message, MessageBox } from 'element-ui'
import { getPage } from '@/api/taskSchool'
import AddIndex from "./dataFilling/index.vue"
import TaskIndex from "../approvalManage/pending/dataFilling/index.vue"
import ExamineLog from '../approvalManage/approved/ExamineLog.vue'

import AddReportItem from '../declareManage/addReItem.vue'
//import TaskIndex from '../../../approvaManage/pending/dataFilling/index.vue'

export default {
  components: { Pagination,AddIndex,TaskIndex,ExamineLog,AddReportItem },
  mixins: [tableHeight2],
  provide() {
    return {
      onloadList: this.loadList
    }
  },
  data() {
    return {
      filterText:null,
      list: null,
      total: 0,
      listLoading: true,
      dialogVisibleDF:false,
      treeLoading:false,
      dialogVisibleLog:false,
      dialogVisibleTask:false,
      listQuery: {
        page: 1,
        limit: 20,
        name: null,
        type: null,
        itemId: this.$getCookie('projectId')
      },
      
      logQuery:{
        limit:10,
        page:1,
        id:null
      },
      apiTypes: [],
      treeList:[],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      tabheight:0,
      logList:[],
      apiDeployStates: [],
      dialogVisibleAdd: false,
      dialogVisibleARI: false,
      dialogVisibleExaLog:false,
      titel: '',
      form: {},
      rules: {
        itemId: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
   mounted(){
     this.$nextTick(function() {
      var filterHeight = document.getElementById('filter-container').offsetHeight
      var tableHeight = document.getElementById('app-contain').offsetHeight
      var pagination = document.getElementById('pagination').offsetHeight
      this.tabheight = tableHeight - filterHeight - pagination-30
      console.log(this.tabheight,filterHeight,tableHeight,pagination)
      window.onresize = () => {
        return (() => {
          var filterHeight = document.getElementById('filter-container').offsetHeight
          var tableHeight = document.getElementById('app-contain').offsetHeight
          var pagination = document.getElementById('pagination').offsetHeight
          this.tabheight = tableHeight - filterHeight - pagination-30
        })()
      }
    })
  },
  created() {
    this.loadList()
    this.getTableTreeList();
    this.getDictList('YW_TASK_SCHOOL_STATE')
  },
  methods: {
    getDictList(code) {
      getDictListByCode(code).then(response => {
        if (code === 'YW_TASK_SCHOOL_STATE') {
          this.apiTypes = response.data
        } else if (code === 'YW_TASK_SCHOOL_STATE') {
          this.apiDeployStates = response.data
        }
      })
    },
    getDicName(code, flag) {
      var dict = []
      if (flag === 'YW_TASK_SCHOOL_STATE') {
        dict = this.apiTypes
      } else if (flag === 'YW_FLOW_STATE') {
        dict = this.apiDeployStates
      }
      for (var i in dict) {
        if (dict[i].code === code) {
          return dict[i].name
        }
      }
    },
    // //操作日志
    // handleLog(data){
    //   this.dialogVisibleLog=true
    //   this.logQuery.id=data.id;
    //   this.geLogList();
    // },
    //  geLogList(){
    //   this.logLoading=true
    //   getLogByDevelopment(this.logQuery).then(response=>{
    //     this.logLoading=false
    //     this.logList=response.data.records;
    //     this.logTotal=parseInt(response.data.total)
    //   }).catch(error=>{
    //     this.logLoading=false
    //   })
    // },
    // //节点过滤
    // handleNodeClick(data){
    //   if(data.level==="1"){
    //     this.listQuery.themeId=data.id;
    //     this.loadList();
    //   }
    // },
    // //节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
     addReportItem() {
      this.dialogVisibleARI = true
    },
    addReport(row) {
      this.$router.push({ path: './dataFilling/fill',query:{task:row}})
      // this.dialogVisibleDF=true
      // this.$nextTick(() => {
      //   this.$refs.dataFillingIndex
      // })
    },
    loadList() {
      this.listLoading = true
      this.listQuery.state=2

      getPage(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.records
        this.total = parseInt(response.data.total)
      }).catch(response => {
        this.listLoading = false
      })
//       this.list=[
//   {
//     "id": "1074999715231170560",
//     "name": "22年学校装备填报",
//     "beginDate": "2023-02-14 10:25:05",
//     "endDate": "2023-02-14 10:25:05",
//     "createTimeTi": "2023-02-14 10:25:05",
//     "createUserId": "13",
//     "createUserName": "用户1",
//     "state": "1"
    
//   }
// ]
      // this.listLoading = false
      // this.total = parseInt(1)
    },
    // handleAdd() {
    //   this.$router.push({ path: './arrange/arrangeAdd' })
    // },
    // handleEdit(row) {
    //   this.$router.push({ path: './arrange/arrangeAdd', query: { id: row.id }})
    // },
    handleSubmitTest(row) {
       this.code=row.stsId
       this.dialogVisibleTask=true
        this.$nextTick(() => {
         this.$refs.taskindex
        })
    },
     handleEdit(row) {
      this.code=row.stsId
      
     this.dialogVisibleExaLog=true
      this.$nextTick(() => {
         this.$refs.taskindex
        })
    },
    // handleRelease(row) {
    //   MessageBox.confirm('确定发布吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     release(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // },
    //树型结构
    getTableTreeList(){
      this.treeLoading=true;
      this.treeList=[{
        name:"教育装备填报",children:[
          {name:"表1 学校基本情况统计表",children:[]},
          {name:"表2 实验室管理人员情况统计表",children:[]},
          {name:"表3 实验室及功能教室用房情况统计表",children:[]},
          {name:"表4 教学仪器情况统计表",children:[]},
          {name:"表5 教学设备情况统计表",children:[]},
          {name:"表6 理科实验开出情况统计表",children:[]},
          {name:"表7 图书管理人员情况统计表",children:[]}
        ]
      }]
      this.treeLoading=false;
      // getDeployTreeList({itemId:this.$getCookie('projectId')}).then(response=>{
      //   this.treeList=response.data;
      //   this.treeLoading=false;
      // }).catch(error=>{
      //   this.treeLoading=false;
      // })
    },
    // handleRevoke(row) {
    //   MessageBox.confirm('确定撤销吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     revoke(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // }
  }
}
</script>
<style lang="less" scoped>
.app-container{
  padding:10px 20px;
  height: 100%;
}
.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .date-filter-item{
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.pagination-container{
  margin-top:0 !important;
  padding: 10px 16px !important;
}
#app-contain{
  height:100%;
  width:100%;
}
</style>
